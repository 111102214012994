var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.rol!='influencer')?_c('v-app',{attrs:{"id":"inspire"}},[_c('v-navigation-drawer',{attrs:{"expand-on-hover":"","color":"#f4f5fa","clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2",staticStyle:{"color":"#9ca3a5","font-size":"12px"}},[_vm._v(" UNOCRM | v4.7.7 "),_c('v-icon',[_vm._v(" mdi-settings")])],1)]},proxy:true}],null,false,3467813846),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"pl-0 pr-4 pt-3 sidebar",attrs:{"dense":""}},[_c('v-expansion-panels',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('saleReports') || _vm.permissions('collectionReports')),expression:"permissions('saleReports') || permissions('collectionReports')"}],staticClass:"sinsombra"},[_c('v-expansion-panel',{staticStyle:{"background":"transparent!important"}},[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"500","font-size":"0.8125rem","color":"#202020","padding":"0px!important"}},[_c('div',[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" mdi-chart-bar")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"text-transform":"uppercase!important"}},[_vm._v("Reportes")])],1)],1)]}}],null,false,2013380801)},[_c('span',[_vm._v("Reportes de ventas por categorías")])])],1)]),_c('v-expansion-panel-content',[_c('v-expansion-panels',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('saleReports')),expression:"permissions('saleReports')"}],staticClass:"sinsombra"},[_c('v-expansion-panel',{staticStyle:{"background":"transparent!important"}},[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"500","font-size":"0.8125rem","color":"#202020","padding":"0px!important"}},[_c('div',[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"text-transform":"uppercase!important"}},[_vm._v("Reporte de Ventas")])],1)],1)]}}],null,false,566878409)},[_c('span',[_vm._v("Reportes de ventas por categorías")])])],1)]),_c('v-expansion-panel-content',[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/registers/total-report"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ventas por Mes")])],1)],1)]}}],null,false,4176452509)},[_c('span',[_vm._v("Reporte de ventas general mensual con ganancia")])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/registers/influencers-reports"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ventas por Influencer")])],1)],1)]}}],null,false,3003218692)},[_c('span',[_vm._v("Reporte de ventas por influencer con ganancia")])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/registers/agencies-reports"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ventas por Agencia")])],1)],1)]}}],null,false,2218847008)},[_c('span',[_vm._v("Reporte de ventas por Agencia con ganancia")])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/registers/department-reports"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ventas por Departamento")])],1)],1)]}}],null,false,2918265721)},[_c('span',[_vm._v("Reporte de ventas por departamento con ganancia")])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/registers/users-reports"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ventas por Responsable")])],1)],1)]}}],null,false,1443860281)},[_c('span',[_vm._v("Reporte de ventas por responsable con ganancia")])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/registers/expired-sales-report"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ventas por Cobrar")])],1)],1)]}}],null,false,3158874937)},[_c('span',[_vm._v("Reporte de ventas marcadas como vencidas")])])],1)],1)],1),_c('v-expansion-panels',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('collectionReports')),expression:"permissions('collectionReports')"}],staticClass:"sinsombra"},[_c('v-expansion-panel',{staticStyle:{"background":"transparent!important"}},[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"500","font-size":"0.8125rem","color":"#202020","padding":"0px!important"}},[_c('div',[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"text-transform":"uppercase!important"}},[_vm._v("Reportes de Cobranza")])],1)],1)]}}],null,false,4207531241)},[_c('span',[_vm._v("Reportes de ventas cobradas por categoría")])])],1)]),_c('v-expansion-panel-content',[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/collections/total-report"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cobranza por Mes")])],1)],1)]}}],null,false,1805224817)},[_c('span',[_vm._v("Reporte de ventas general mensual con ganancia")])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/collections/influencers-reports"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cobranza por Influencer")])],1)],1)]}}],null,false,1420369864)},[_c('span',[_vm._v("Reporte de ventas por influencer con ganancia")])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/collections/agencies-reports"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cobranza por Agencia")])],1)],1)]}}],null,false,4235208844)},[_c('span',[_vm._v("Reporte de ventas por Agencia con ganancia")])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/collections/department-reports"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cobranza por Departamento")])],1)],1)]}}],null,false,3615078421)},[_c('span',[_vm._v("Reporte de ventas por departamento con ganancia")])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/collections/users-reports"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cobranza por Responsable")])],1)],1)]}}],null,false,1706391093)},[_c('span',[_vm._v("Reporte de ventas por responsable con ganancia")])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/collections/past-due"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cobranza Vencida")])],1)],1)]}}],null,false,4088665947)},[_c('span',[_vm._v("Reporte de ventas que no se han cobrado y ya paso su fecha de vencimiento")])])],1)],1)],1)],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('clients')),expression:"permissions('clients')"}]},[_c('v-divider',{staticClass:"ma-4"}),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/clients","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" mdi-account-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Clientes")])],1)],1)]}}],null,false,2039241914)},[_c('span',[_vm._v("Lista de empresas a las que se les ha cotizado/vendido")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('accessToVerifyAgencies')),expression:"permissions('accessToVerifyAgencies')"}]},[_c('v-divider',{staticClass:"ma-4"}),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/verify-agencies","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" mdi-account-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Verificar Agencias")])],1)],1)]}}],null,false,2537844918)},[_c('span',[_vm._v("Lista de empresas a las que se les ha cotizado/vendido")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('influencers')),expression:"permissions('influencers')"}]},[_c('v-divider',{staticClass:"ma-4"}),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/influencers","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" mdi-account-group")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Influencers")])],1)],1)]}}],null,false,1616933136)},[_c('span',[_vm._v("Lista de influencers")])])],1),_c('v-divider',{staticClass:"ma-4"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('sales')),expression:"permissions('sales')"}]},[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/sales","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-currency-usd")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ventas")])],1)],1)]}}],null,false,1089877400)},[_c('span',[_vm._v("Lista de ventas cerradas")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('bankMovements')),expression:"permissions('bankMovements')"}]},[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/bank_movements","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-cash-check")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Movimientos Bancarios")])],1)],1)]}}],null,false,2841385962)},[_c('span',[_vm._v("Lista de movimientos del banco")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('expiredSales')),expression:"permissions('expiredSales')"}]},[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/balances","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-credit-card-clock-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ventas por Cobrar")])],1)],1)]}}],null,false,983899923)},[_c('span',[_vm._v("Lista de ventas marcadas como vencidas")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('salesToAuthorize')),expression:"permissions('salesToAuthorize')"}]},[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/sales-to-authorize","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-credit-card-search-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ventas por Autorizar")])],1)],1)]}}],null,false,649603938)},[_c('span',[_vm._v("Lista de ventas que requieren autorización")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('cancelations')),expression:"permissions('cancelations')"}]},[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/canceled","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cancelaciones")])],1)],1)]}}],null,false,3811868698)},[_c('span',[_vm._v("Cotizaciones que no se cerraron por algun motivo")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('expenses')),expression:"permissions('expenses')"}]},[_c('v-divider',{staticClass:"ma-4"}),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/expenses","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-currency-usd-off")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Gastos")])],1)],1)]}}],null,false,3273047063)},[_c('span',[_vm._v("Lista de gastos")])])],1)],1)],1),_c('v-app-bar',{staticClass:"elevation-0 px-0",attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":"#f4f5fa","dark":""}},[_c('v-icon',{staticClass:"mr-5",attrs:{"color":"grey"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_vm._v(" mdi-menu")]),_c('img',{staticStyle:{"height":"40px"},attrs:{"src":"/logo.png"}}),_c('v-spacer'),_c('v-btn',{staticClass:"hidden-sm-and-down pa-0",attrs:{"icon":"","to":"/","link":""}},[_c('v-icon',{attrs:{"color":"#707073"}},[_vm._v("mdi-apps")])],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"size":"32px"}},[_c('img',{attrs:{"src":"/avatar.png"}})]),_c('span',{staticClass:"hidden-sm-and-down",staticStyle:{"color":"#707073","margin-left":"10px","letter-spacing":"0px","font-size":"16px","font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.currentUser.name)+" "+_vm._s(_vm.currentUser.last))]),_vm._v(" "),_c('v-icon',{attrs:{"color":"#707073"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,4291256501)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{attrs:{"link":"","to":"/settings"}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" mdi-cog")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ajustes")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-power")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cerrar Sesión")])],1)],1)],1)],1)],1)],1),_c('v-main',{staticClass:"pa-0",staticStyle:{"background-color":"#f4f5fa"}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('router-view',{on:{"closeDrawer":_vm.closeDrawer}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }